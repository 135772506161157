.App {
  background-color: #282c34;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;  /* Ensures the app takes up the full height of the viewport */
  color: white;  /* Sets the text color */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex-shrink: 0; /* Prevents the header from shrinking */
}

.App-content {
  color: white;
  flex-grow: 1; /* Ensures content section grows to fill available space */
  display: flex;
  flex-direction: column; /* Aligns children (Settings and SpellingPractice) in a column */
  align-items: center; /* Centers children horizontally */
  padding: 20px; /* Adds some spacing inside the content div */
  overflow-y: auto; /* Allows scrolling if content overflows */
}

.App-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid white;
  padding: 20px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  button {
    padding: 0.5em 1em;
    font-size: 1em;
  }

  input[type="text"] {
    padding: 0.5em;
    font-size: 1.2em;
  }
}

.App-spelling-practice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  border: 1px solid white;
  padding: 20px;
  border-radius: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
